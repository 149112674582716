body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100wh; 
  height: 50vh;
}

.color-primary0- { color: #81AF52 }	/* Main Primary color */
.color-primary-1 { color: #D2ECB7 }
.color-primary-2 { color: #ACD485 }
.color-primary-3 { color: #60902F }
.color-primary-4 { color: #3D6810 }

.color-secondary-1-0 { color: #408864 }	/* Main Secondary color (1) */
.color-secondary-1-1 { color: #99C5AF }
.color-secondary-1-2 { color: #67A586 }
.color-secondary-1-3 { color: #247049 }
.color-secondary-1-4 { color: #0D512F }

.color-secondary-2-0 { color: #C2745B }	/* Main Secondary color (2) */
.color-secondary-2-1 { color: #FFD4C6 }
.color-secondary-2-2 { color: #EBA893 }
.color-secondary-2-3 { color: #9F4E34 }
.color-secondary-2-4 { color: #742A12 }

.color-complement-0 { color: #AA5070 }	/* Main Complement color */
.color-complement-1 { color: #E7B3C5 }
.color-complement-2 { color: #CE819C }
.color-complement-3 { color: #8C2D4F }
.color-complement-4 { color: #66102E }

.submenu-item {
  padding-left: 20px; /* Adjust the value as needed for the desired indentation */
}